<template>
    <v-app id="app">
        <v-app-bar app dense clipped-left tile short outlined flat class="py-0">
            <v-toolbar-title class="mr-2">{{
                $route.name
            }}</v-toolbar-title>
            <v-divider vertical></v-divider>

            <v-toolbar-items style="flex-grow:1">
                <uv-page-menu :page-menu="$store.state.pageMenu"></uv-page-menu>
                
                <div class="d-inline-flex" style="flex-grow:1">
                    <v-spacer></v-spacer>
                    <v-divider vertical></v-divider>
                    <v-menu open-on-hover offset-y>
                        <template #activator="{on, attrs}">
                            <v-btn :color="loggedIn ? 'success' : 'primary'" icon v-on="on" v-bind="attrs"
                        >
                            <v-icon>{{icons.mdiAccountCircle}}</v-icon>
                            <v-icon x-small>{{icons.mdiChevronDown}}</v-icon>
                        </v-btn>    
                        </template>
                        <v-list>
                            <v-list-item v-if="!loggedIn" link to="/login">Login</v-list-item>
                            <template v-else>
                                <v-list-item link @click="logout">Logout</v-list-item>
                                <v-list-item link @click="changePwd = true">Change password</v-list-item>
                            </template>
                        </v-list>
                    </v-menu>
                </div>
            
            
            </v-toolbar-items>
        </v-app-bar>
        
        <v-navigation-drawer app expand-on-hover permanent fixed clipped v-if="loggedIn" mini-variant-width=56>
            <v-list-item-group color="primary darken-4" dense flat>
                <template v-for="(item, i) in navMenu">
                    <v-list-item :key="i + '_item'"
                        :to="item.to"
                        class="py-0"
                    >
                        <v-list-item-icon>
                            <v-icon color="primary">{{ item.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            {{ item.name }}
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider :key="i + '_divider'"></v-divider>
                </template>
                <v-menu offet-x :nudge-width="200">
                    <template #activator="{on,attrs}">
                        <v-list-item class="py-0">                    
                            <v-list-item-icon>
                                <v-icon color="primary">{{icons.mdiHelp}}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content v-on="on" v-bind="attrs">
                                Help
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                    <v-card>
                        <v-list-item-group>
                            <v-list-item v-for="(item, i) in helpLinks" :key="i + '_item'"
                                :href="item.location"
                                target="_blank"
                            >
                                <v-list-item-icon>
                                    <v-icon color="red">{{icons.mdiFilePdfBox}}</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    {{ item.name }}
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-card>
                </v-menu>
                <v-divider></v-divider>
            </v-list-item-group>
            <template #append>
                <v-divider></v-divider>
                <v-layout justify-center>
                    <v-img src="@/assets/logo.svg" contain max-height="56px"></v-img>
                </v-layout>
            </template>
        </v-navigation-drawer>
    

        <uv-message app :message="$store.state.globalMessage"></uv-message>
        <v-main app>
            <v-container fluid class="pa-0" id="main-container" v-scroll.self="onScroll" style="height: 100%">
                <v-slide-y-transition>
                    <router-view></router-view>
                </v-slide-y-transition>
            </v-container>
        </v-main>

        <change-password v-model="changePwd"></change-password>
    </v-app>
</template>

<script>
import UvMessage from "./components/Message";
import UvPageMenu from "./components/PageMenu";


import ChangePassword from "./views/App/ChangePassword";
import { mapMutations, mapState, mapActions } from 'vuex';
import { reactive } from "vue";
import {mdiFileChart, mdiShieldAccount,mdiViewDashboard} from "@mdi/js";
export default {
    setup() {
        const icons = reactive({})
        import("@mdi/js").then((mdi) => {
            icons.mdiAccountCircle = mdi.mdiAccountCircle
            icons.mdiChevronDown = mdi.mdiChevronDown
            icons.mdiHelp = mdi.mdiHelp
            icons.mdiFilePdfBox = mdi.mdiFilePdfBox
        })
        return {icons}
    },
    created: function() {
        let apitoken = localStorage.getItem("apitoken");
        let refreshtoken = localStorage.getItem("refreshtoken");

        if (apitoken) {
            this.$store.commit("SET_apiToken", JSON.parse(apitoken));
            this.$store.commit("SET_refreshToken", refreshtoken);
        }

        document.addEventListener("swUpdated", (e)=>{
            let registration = e.detail;
            registration?.waiting?.postMessage({ type: "SKIP_WAITING" });
        });
        if (navigator.serviceWorker) {
            navigator.serviceWorker.addEventListener("controllerchange", () => {
                if (this.refreshing) return;
                this.refreshing = true;
                window.location.reload();
            });
        }

        const files = require.context("@/assets/Manual_XBRL", true, /\.pdf$/);
        files.keys().forEach((key) => {
            let f = files(key)
            this.helpLinks.push({
                name: key.replace(/\.\/\d+_/, "")
                        .replace(".pdf", "")
                        .replace("Manual_XBRL_", "")
                        .replace(/_/g, " "),
                location:  f
            });
        });
    },
    destroyed: function() {
        clearTimeout(this.timeout);
    },
    mounted: function() {
        this.getXbrlUnits(this);
    },
    data: () => ({
        changePwd: false,
        refreshing: false,
        helpLinks: [],
        showHelp: false,
        timeout: null
    }),
    computed: {
        ...mapState(["scrollOffsetTop", "userRole", "loggedIn", "apiToken"]),
        navMenu() {
            let menuItems = [
                {
                    icon: mdiViewDashboard,
                    name: "Dashboard",
                    to: "/"
                },
                {
                    icon: mdiFileChart,
                    name: "Xbrl",
                    to: "/reports"
                },
            ]
            if (this.userRole === 1){
                menuItems.push({
                    icon: mdiShieldAccount,
                    name: "Administration",
                    to: "/admin"
                })
            }
            return menuItems;
        }
    },
    methods:{
        ...mapMutations({
            setScrollOffsetTop: "SET_scrollOffsetTop"
        }),
        ...mapActions("xbrlData", [
            "getXbrlUnits"
        ]),
        logout: function(){
            this.$store.commit("REMOVE_apiToken");
            this.$router.push("/login");
        },
        onScroll(e) {
            this.setScrollOffsetTop(e.target.scrollTop);
        },
        async refreshToken() {
            let res = await this.$apiHttp({
                        method: "post",
                        url:"/auth/refresh_token",
                        data:{
                            username: this.$store.state.userName,
                            token: this.$store.state.refreshToken  
                        }
                    })

            let refresh_token = res.data.data.refresh_token
            delete res.data.data.refresh_token
            this.$store.commit("SET_apiToken", res.data.data);
            this.$store.commit("SET_refreshToken", refresh_token);   
        },
    },
    components: {
        UvMessage,
        UvPageMenu,
        ChangePassword
    },
    watch: {
        apiToken() {
            if (this.apiToken) {
                let diff = localStorage.getItem("expiring") - Math.round(new Date().getTime()/1000)
                if (diff <= 0) {
                    this.refreshToken()
                }
                else {
                    this.timeout = setTimeout(this.refreshToken, diff - 3600)    
                }
            }
            
        }
    }
};
</script>

<style>
.v-app-bar {
    background-color: white !important;
}
#app {
    height: 100vh;
}

.v-main {
    height: 100%;
    padding-left: 56px!important;
}

#main-container {
    overflow-y: auto;
}
</style>